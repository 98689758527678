.react-datepicker__close-icon {
  position: absolute;
  right: 6px;
  top: 1.2rem;
  transform: translateY(-50%);
  cursor: pointer;
}

.react-datepicker__close-icon::after {
  background-color: transparent; /* Adjust the color if needed */
  border-radius: 50%;
  color: #0000008a;
  font-size: 26px;
  padding-left: 2px;
  right: 2px;
}


.highlight {
  background-color: yellow;
  font-weight: bold;
}